import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import ActionBar from "./components/ActionBar";
import ViewCenter from "./components/ViewCenter";
import classNames from "classnames";
import {useParams} from "react-router-dom";
import {
  uiSelectorFromWaiting,
  uiSelectorInviteNewPerson, uiSelectorIsAwaitReady,
  uiSelectorIsSmall,
  uiSelectorIsXSmall,
  uiSelectorRout
} from "../../redux/selectors/ui";
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";
import ElectronCallHeader from "./components/ElectronCallHeader/ElectronCallHeader";
import WebCallsHeader from "./components/WebCallsHeader/WebCallsHeader";
import {getOs} from "../../invorkers";
import {setIsHide, setOs} from "../../redux/slices/ui";
import {DOMAIN, ROUT_TYPES} from "../../constants/contants";
import {fullScreenHandle} from "../../helpers/common";
import {Center, PageLayout} from "@clout-team/web-components";
import RightBar from "./components/RightBar/RightBar";
import BannerFirstEnter from "../../components/Banners/FirstEnter";
import InviteUsers from "../../components/Banners/InviteUsers";
import WaitingEntry from "../../components/Banners/WaitingEntry/WaitingEntry";
import "./index.scss";
import {setConference, setConferenceInfo} from "../../redux/slices/conferense";
import PageWaiting from "../waiting";
import PageFinish from "../finish";
import PageError from "../error";
import {settingsSelectorIsInvertRightBar} from "../../redux/selectors/settings";
import {contextRoot} from "../../App";
import {isShowLoading} from "../../helpers/functions";
import {emitEvent} from "../../helpers/socketHelper";

const DialogsConfirm = React.lazy(
  () => import("../../components/DialogsConfirm/DialogsConfirm")
);
const ContextMenus = React.lazy(() => import("../../components/ContextMenus"));
const Errors = React.lazy(() => import("./components/Errors/Errors"));
const CarouselParticipants = React.lazy(
  () => import("./components/CarouselParticipants")
);

const PageIndex = () => {
  let {hash} = useParams();
  const dispatch = useDispatch();

  const small = useSelector(uiSelectorIsSmall),
    xSmall = useSelector(uiSelectorIsXSmall),
    showInvitePersons = useSelector(uiSelectorInviteNewPerson),
    router = useSelector(uiSelectorRout),
    fromWaiting = useSelector(uiSelectorFromWaiting),
    invertedTheme = useSelector(settingsSelectorIsInvertRightBar),
    isAwaitReady = useSelector(uiSelectorIsAwaitReady)

  useEffect(() => {
    if (!isAwaitReady) {
      setTimeout(() => isShowLoading(false), 300);
    }
  }, [isAwaitReady])

  useEffect(() => {
    if (invertedTheme) {
      contextRoot?.classList?.add("inverted-theme-aware");
    } else {
      contextRoot?.classList?.remove("inverted-theme-aware");
    }
  }, [invertedTheme]);

  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", appHeight);
  appHeight();

  useEffect(() => {
    if (hash && !fromWaiting) {
      dispatch(setConference({hash: hash, link: `${DOMAIN}/${hash}`}));
      dispatch(setConferenceInfo(hash));

      emitEvent({
        'event': 'status',
        'link': hash
      })
    }
  }, [hash, fromWaiting])

  useEffect(() => {
    if (isElectron()) {
      getOs().then((res: string) => dispatch(setOs(res)))
    }

    let timeOutMove: ReturnType<typeof setTimeout>, isHide = false;
    const moveHandle = (e: any) => {
      const target = e.target;
      if (!isHide) {
        dispatch(setIsHide(false))
        isHide = true;
      }

      clearTimeout(timeOutMove);
      timeOutMove = setTimeout(() => {
        // если мышка на UI или открыто контекстное меню, то не убираем
        if (
          contextRoot && !contextRoot.classList.contains('active') &&
          !target.closest('.action-bar') &&
          !target.closest('.info-bar-item')
        ) {
          dispatch(setIsHide(true))
          isHide = false;
        }
      }, 3000)
    }

    document.addEventListener("mousemove", moveHandle);
    return () => {
      document.removeEventListener("mousemove", moveHandle);
      clearTimeout(timeOutMove);
    };
  }, []);

  // useEffect(() => {
  //   if (
  //     router !== ROUT_TYPES.GUEST &&
  //     router !== ROUT_TYPES.FINISH &&
  //     router !== ROUT_TYPES.ERROR_LINK
  //   )
  //     changeTheme(THEMES.DARK_BRIGHT);
  // }, [router]);

  return useMemo(() => {
    if (router === ROUT_TYPES.GUEST) {
      return <PageWaiting/>;
    } else if (router === ROUT_TYPES.FINISH) {
      return <PageFinish/>;
    } else if (router === ROUT_TYPES.ERROR_LINK) {
      return <PageError/>;
    } else {
      return (
        <>
          <BannerFirstEnter/>
          <WaitingEntry/>
          {showInvitePersons && <InviteUsers/>}
          <PageLayout>
            <Center>
              <div
                onDoubleClick={() => fullScreenHandle()}
                className={classNames("page-index", {
                  "is-small": small || xSmall,
                  "is-x-small": xSmall,
                })}
              >
                <Errors/>
                <div className={`outgoing-call`}>
                  {isElectron() ? <ElectronCallHeader/> : <WebCallsHeader/>}
                  <CarouselParticipants/>
                  <ViewCenter/>
                  <ActionBar/>
                </div>
                <DialogsConfirm/>
                <ContextMenus/>
              </div>
            </Center>
            <RightBar/>
          </PageLayout>
        </>
      );
    }
  }, [router, showInvitePersons, small, xSmall]);
};

export default PageIndex;
