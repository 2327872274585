import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {User} from "../../../../interfaces/user";
import {
  userPinnedSelector,
  userActiveSelector,
  usersInMeetingSelector, userCurrentSelector
} from "../../../../redux/selectors/users";
import {uiSelectorIsHide, uiSelectorLayout} from "../../../../redux/selectors/ui";
import {LAYOUTS, PARTICIPANT_STATUS} from "../../../../constants/contants";
import {setPinnedUser, unpinUser} from "../../../../redux/slices/users";
import {Participant} from "../../../../components/Participant";
import LayoutGrid from "../../../../components/LayoutGrid/LayoutGrid";
import {calcVideoSize, formatUserName, setMediaSize} from "../../../../helpers/functions";
import {setLayout} from "../../../../redux/slices/ui";

let observer: any = null;
const actionBarHeight = 66;

const ViewCenterVideo = React.memo(() => {
  let activeUser: User.IUsersTo = useSelector(userActiveSelector)
  const dispatch = useDispatch(),
    lang = useSelector((state: any) => state.localization),
    L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]),
    layout = useSelector(uiSelectorLayout),
    usersInMeeting: User.IUsersTo[] = useSelector(usersInMeetingSelector),
    pinnedUser = useSelector(userPinnedSelector),
    isHide = useSelector(uiSelectorIsHide),
    localUser = useSelector(userCurrentSelector),
    [update, setUpdate] = useState(0)

  if (pinnedUser) activeUser = pinnedUser;

  const condition = (activeUser.isShare && !activeUser.isLocal) || layout === LAYOUTS.GRID ||
    (
      activeUser.video &&
      activeUser.videoResolution &&
      activeUser.videoResolution.width < activeUser.videoResolution.height
    );

  useEffect(() => {
    window.usersStreams.registerListener(function (val) {
      setUpdate(Math.random());
    });
  }, [])

  useEffect(() => {
    const userNames: any = document.querySelectorAll('.participant-card__name');
    userNames.forEach(v => v.removeAttribute('style'))
  }, [layout])

  useEffect(() => {
    if (activeUser) {
      const div = document.getElementById('main-video'),
        current = document.getElementById('native-video-size')


      const disconnectObserver = () => {
        if (observer) {
          observer.disconnect();
          observer = null;
        }
      }

      if (div) {
        const removeUserNameStyle = () => {
          const userNames: any = document.querySelectorAll('.participant-card__name');
          userNames.forEach(v => v.removeAttribute('style'))
        }

        const setSize = (size, height) => {
          if (current) {
            const userName: any = current.querySelector('.participant-card__name');

            if (condition) {
              if (userName) userName.removeAttribute('style');
              current.removeAttribute('style');
            } else {
              current.style.width = `${size.width}px`;
              current.style.height = `${size.height}px`;
            }

            if (userName) {
              userName.style.transition = '0s';
              const centerOffsetBottom = height - (current.offsetTop + current.offsetHeight)
              if (centerOffsetBottom <= actionBarHeight) {
                setTimeout(() => {
                  userName.style.bottom = `${actionBarHeight - centerOffsetBottom}px`;
                })
              } else userName.removeAttribute('style');
            }
          } else {
            const grid = document.getElementById('center-grid');
            if (grid) grid.removeAttribute('style');

            removeUserNameStyle();
          }
        }

        const resizeHandle = (entries: any) => {
          for (const entry of entries) {
            const width = entry.borderBoxSize ? entry.borderBoxSize[0].inlineSize : entry.contentRect.width,
              height = entry.borderBoxSize ? entry.borderBoxSize[0].blockSize : entry.contentRect.height,
              size = calcVideoSize(width, height)

            setSize(size, height);

            // класс для адаптации кнопок
            setMediaSize(width);
          }
        }

        observer = new ResizeObserver(resizeHandle);
        observer.observe(div);

        return () => {
          removeUserNameStyle();
          disconnectObserver();
        }
      }

    }
  }, [activeUser, condition])

  const unpinUserHandle = useCallback(() => {
    dispatch(unpinUser(pinnedUser.id))
  }, [dispatch, pinnedUser])

  const renderGrid = useMemo(() => {

    // если есть запиненный он всегда в начале
    const sortArray = usersInMeeting.slice();
    // @ts-ignore
    if (pinnedUser) sortArray.sort(x => x.isPinned ? -1 : 1)

    return <LayoutGrid childs={
      sortArray.map(u => {
        return <Participant
          key={u.id}
          userId={u.id}
          localUserId={localUser?.id}
          className={`video-${u.id}`}
          isSpeaking={u.isSpeaking}
          fullName={formatUserName(u)}
          isVideo={u.video}
          isSharing={u.isShare}
          isYou={u.isLocal}
          isMutedAudio={!u.audio}
          isHost={u.isHost}
          isPinned={u.isPinned}
          onUnPinClick={unpinUserHandle}
          avatar={u.avatar}
          stream={window.usersStreams.streams[u.id]}
          isConteinVideo={false}
          isShowButtons={false}
          isShowUnpinButton={!isHide}
          isHideUserName={isHide}
          L={L}
          onDoubleClick={(e: any) => {
            e.stopPropagation();
            if (!u.isPinned) dispatch(setPinnedUser(u))
            dispatch(setLayout(LAYOUTS.STACK));
          }}
        />
      })
    }
    />
  }, [unpinUserHandle, pinnedUser, usersInMeeting, isHide, L, update, localUser?.id])

  return (
    <>
      {
        layout === LAYOUTS.GRID ?
          <div className={'layout-grid-wrapper'} id={'center-grid'}>
            {renderGrid}
          </div>
          : (
            <div id={'native-video-size'} className={'video-wrapper'}>
              <Participant
                key={activeUser.id}
                userId={activeUser.id}
                localUserId={localUser?.id}
                className={`video-${activeUser.id}`}
                status={PARTICIPANT_STATUS.NONE}
                fullName={formatUserName(activeUser)}
                isSpeaking={false}
                isVideo={activeUser.video}
                isSharing={activeUser.isShare}
                isYou={activeUser.isLocal}
                isMutedAudio={!activeUser.audio}
                isHost={activeUser.isHost}
                isPinned={activeUser.isPinned}
                onUnPinClick={unpinUserHandle}
                avatar={activeUser.avatar}
                stream={window.usersStreams.streams[activeUser.id]}
                isConteinVideo={condition}
                isShowButtons={false}
                debug={
                  (window.location.host !== 'meet.lenzaos.com' && window.location.host !== 'app.meet.lenzaos.com')
                }
                isShowUnpinButton={!isHide}
                isHideUserName={(activeUser.isShare && !activeUser.isLocal) ? true : isHide}
                L={L}
              />
              {/*{layout === LAYOUTS.SPOTLIGHT && !isSmall && <BannerMuted L={L}/>}*/}
            </div>
          )
      }
    </>
  );
});

export default ViewCenterVideo;
