import React, {Suspense, useEffect, useMemo, useRef} from 'react';
import {useSelector} from "react-redux";
import {uiSelectorRightBar} from "../../../../redux/selectors/ui";
import {PageLayout, Loading} from "@clout-team/web-components";
import classNames from "classnames";
import '../CommentsRightBar/CommentsRightBar.scss';
import {RIGHT_BAR_TYPES} from "../../../../constants/contants";
import {settingsSelectorIsInvertRightBar} from "../../../../redux/selectors/settings";

const ParticipantsRightBar = React.lazy(() => import("../ParticipantsRightBar"));
const CommentsRightBar = React.lazy(() => import("../CommentsRightBar/CommentsRightBar"));

const RightBar = React.memo(() => {
  const rightBarType = useSelector(uiSelectorRightBar),
    isInvertRightBar = useSelector(settingsSelectorIsInvertRightBar),
    disableView = useRef(false)

  useEffect(() => {

    const resizer: HTMLElement | null = document.querySelector('.resizer');
    if (resizer) {
      if (rightBarType !== '') resizer.style.display = 'block';
      else resizer.style.display = 'none';
    }
  }, [rightBarType])

  const renderLoad = useMemo(() => {
    return <div className={'rp-import-load'}>
      <Loading isNewDesign/>
    </div>
  }, [])

  return (rightBarType !== '') ? (
    <PageLayout.Right className={
      classNames(
        'comments-right-bar',
        {
          "inverted-theme-aware": isInvertRightBar
        }
      )}
    >
      {
        rightBarType === RIGHT_BAR_TYPES.PARTICIPANTS && (
          <Suspense fallback={renderLoad}>
            <div className={classNames(`right-bar-list`, `right-bar-list-${RIGHT_BAR_TYPES.PARTICIPANTS}`)}>
              <ParticipantsRightBar/>
            </div>
          </Suspense>
        )
      }
      {
        rightBarType === RIGHT_BAR_TYPES.COMMENTS && (
          <Suspense fallback={renderLoad}>
            <div className={classNames(`right-bar-list`, `right-bar-list-${RIGHT_BAR_TYPES.COMMENTS}`)}>
              <CommentsRightBar disableView={disableView.current}/>
            </div>
          </Suspense>
        )
      }
    </PageLayout.Right>
  ) : <></>
})

export default RightBar;
