import {createAsyncThunk, createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";
import {User} from "../../interfaces/user";
import {USERS} from "../../constants/meeting";
import {RootState} from "../stores/callStore";
import {setConfirmType} from "./ui";
import {DIALOG_CONFIRM} from "../../constants/contants";
import {SOCKET_ACTIONS} from "../../constants/socket";
import {Emit, emitLog} from "../../helpers/socketHelper";
import {JanusSingleton} from "../../janus/singleton";
import {profileGetSync} from "../../api/calls";
import {ShareSingleton} from "../../janus/shareSingleton";
import {getDate, meetLog} from "../../helpers/log";
import {delDebugUser} from "./debug";

export const setPinnedUser = createAsyncThunk<any,
  User.IUsersTo>(
  'user/setPinned',
  async (user, thunkAPI) => {
    thunkAPI.dispatch(updateUserPinned(user))

    return {...user}
  }
)

export const setUserAsync = createAsyncThunk<any,
  User.IUsersTo,
  { state: RootState }>(
  'user/addUser',
  async (user, thunkAPI) => {
    const {
      meetingUsers: {users}
    } = thunkAPI.getState();

    if (users.find(u => u.id === user.id)) return;
    thunkAPI.dispatch(addUser(user))
  }
)

export const deleteUserAsync = createAsyncThunk<any,
  number,
  { state: RootState }>(
  'user/deleteUser',
  async (id, thunkAPI) => {
    try {
      if (window.usersStreams.streams[id]) {
        delete window.usersStreams.streams[id];
      }
    } catch (e) {
      meetLog(e);
    }

    thunkAPI.dispatch(deleteUser(id))
    thunkAPI.dispatch(delDebugUser(id))
  }
)

export const setUserSpeaking = createAsyncThunk<any,
  { userId: number, isSpeak?: boolean },
  { state: RootState }>(
  'user/setUserSpeaking',
  async (data, thunkAPI) => {

    const {
      meetingUsers: {users}
    } = thunkAPI.getState();

    let newUserData: any = {
      id: data.userId,
      isSpeaking: data.isSpeak,
    }

    if (data.isSpeak === true) {
      newUserData.lastSpeak = Date.now();
      const isActive = users.find(u => u.isActive);
      if (isActive) {
        const local = users.find(u => u.isLocal);
        if (
          local &&
          local.id !== data.userId &&
          isActive.id !== data.userId &&
          !isActive.isShare &&
          isActive.lastSpeak + 2000 < newUserData.lastSpeak
        ) {
          thunkAPI.dispatch(updateUser({id: isActive.id, isActive: false}));
          newUserData.isActive = true;
        }
      }
    }

    thunkAPI.dispatch(updateUser(newUserData));
  }
)

export const cancelUserAsync = createAsyncThunk<any,
  number,
  { state: RootState }>(
  'user/cancelUser',
  async (id, thunkAPI) => {
    thunkAPI.dispatch(cancelUser(id));
    const {
      conference: {conference: {hash}}/*,
      meetingUsers: {users}*/
    } = thunkAPI.getState();

    Emit(SOCKET_ACTIONS.MEETING_KICK, {link: hash, id: id})
  }
)

export const checkShareUser = createAsyncThunk<any,
  User.IUsersTo,
  { state: RootState }>(
  'user/updateUserLocal',
  async (user, thunkAPI) => {

    const {
      meetingUsers: {users},
      settings: {conferenceSettings: {everyoneCanShare}},
    } = thunkAPI.getState();

    const localUser = users.find(u => u.isLocal)
    if (!everyoneCanShare && (user.id / 1000) !== localUser.id && user.isShare) {
      ShareSingleton.stopShareScreen();
    }

  }
)

export const toggleMuteAsync = createAsyncThunk<any,
  User.IUsersTo,
  { state: RootState }>(
  'user/updateUserLocal',
  async (user, thunkAPI) => {
    const isAudio = !user.audio;

    if (user.isLocal) {
      thunkAPI.dispatch(updateUserLocal({audio: isAudio}))

      const {
        conference: {conference: {hash}},
      } = thunkAPI.getState();

      if (user.audio) {
        JanusSingleton.muteAudio()
        Emit(SOCKET_ACTIONS.MEETING_END_AUDIO, {link: hash})
      } else {
        JanusSingleton.unmuteAudio()
        Emit(SOCKET_ACTIONS.MEETING_START_AUDIO, {link: hash})
      }
    } else {
      thunkAPI.dispatch(setConfirmType({type: DIALOG_CONFIRM.MUTE_USER, data: user}));
    }
  }
)

export const toggleVideoAsync = createAsyncThunk<any,
  User.IUsersTo,
  { state: RootState }>(
  'user/updateUserLocal',
  async (user, thunkAPI) => {
    const isVideo = !user.video;
    if (user.isLocal) {
      const {
        conference: {conference: {hash}},
      } = thunkAPI.getState();

      thunkAPI.dispatch(updateUserLocal({video: isVideo/*, isLoadingVideo: true*/}))

      if (isVideo) {
        JanusSingleton.unmuteVideo()
        Emit(SOCKET_ACTIONS.MEETING_START_VIDEO, {link: hash})
      } else {
        JanusSingleton.muteVideo()
        Emit(SOCKET_ACTIONS.MEETING_END_VIDEO, {link: hash})
      }

      // thunkAPI.dispatch(updateUserLocal({video: isVideo, isLoadingVideo: false}))

    } else {
      thunkAPI.dispatch(setConfirmType({type: DIALOG_CONFIRM.TURN_OFF_VIDEO, data: user}));
    }
  }
)

const resProfile: any = profileGetSync(),
  isHasProfile = resProfile && resProfile.response && !resProfile.response.headers ? resProfile.response : null;

const usersSlice: Slice = createSlice({
  name: 'users',
  initialState: {
    users: [
      {
        isPinned: false,
        key: USERS.GUEST,
        name: isHasProfile ? isHasProfile.name : '',
        surname: isHasProfile ? isHasProfile.surname : '',
        avatar: '',
        id: Date.now(),
        answer: true,
        audio: true,
        video: true,
        isHost: false,
        isOwner: false,
        cloutId: ``,
        isActive: true,
        isShare: false,
        isLocal: true,
        lastSpeak: 0,
      }
    ],
    profile: isHasProfile ? isHasProfile : null,
    nowAtMeet: []
  },
  reducers: {
    setNowAtMeet: (state, {payload}: PayloadAction<any>) => {
      state.nowAtMeet = payload
    },
    setProfile: (state, {payload}: PayloadAction<any>) => {
      state.profile = payload
    },
    setUsers: (state, {payload}: PayloadAction<any>) => {
      state.users = payload
    },
    addUser: (state, {payload}) => {
      if (payload.isActive) {
        if (payload.isShare) {
          const indexActive = state.users.findIndex((v: User.IUsersTo) => v.isActive === true);
          if (indexActive > -1) state.users[indexActive].isActive = false;
        } else {
          const shareActive = state.users.findIndex((v: User.IUsersTo) => v.isActive === true && v.isShare);
          if (shareActive > -1) {
            payload.isActive = false;
          } else {
            const indexActive = state.users.findIndex((v: User.IUsersTo) => v.isActive === true);
            if (indexActive > -1) state.users[indexActive].isActive = false;
          }
        }
      }

      if (payload.isPinned) {
        const indexPinned = state.users.findIndex((v: User.IUsersTo) => v.isPinned === true);
        if (indexPinned > -1) state.users[indexPinned].isPinned = false;
      }

      state.users = [...state.users, payload].sort((a: User.IUsersTo, b: User.IUsersTo) => {
        return a.id > b.id ? -1 : 1
      })
    },
    deleteUser: (state, {payload}: PayloadAction<any>) => {
      const index = state.users.findIndex((v: User.IUsersTo) => v.id === payload);
      if (index > -1) {
        const removed = state.users.splice(index, 1);

        state.users.forEach(u => {
          u.isActive = false;
        })
        // проверяем есть ли шаринг
        const shareUserIndex = state.users.findIndex(u => u.isShare && !u.isLocal);
        if (shareUserIndex > -1) {
          state.users[shareUserIndex].isActive = true
          return;
        }

        // берем не себя если есть
        const responderIndex = state.users.findIndex(u => !u.isLocal);
        if (responderIndex > -1) {
          state.users[responderIndex].isActive = true
          return;
        }

        // остаюсь только я
        state.users[0].isActive = true;
      }
    },
    updateUser: (state, {payload}: PayloadAction<any>) => {
      const index = state.users.findIndex((v: User.IUsersTo) => v.id === parseInt(payload.id));
      state.users[index] = {...state.users[index], ...payload};

      // проверим что точно есть активный, но тут никогда не должен отрабатывать
      const isActive = state.users.find(u => u.isActive === true);
      if (!isActive) {
        // проверяем есть ли шаринг
        const shareUserIndex = state.users.findIndex(u => u.isShare && !u.isLocal);
        if (shareUserIndex > -1) {
          state.users[shareUserIndex].isActive = true
          return;
        }

        // берем не себя если есть
        const responderIndex = state.users.findIndex(u => !u.isLocal);
        if (responderIndex > -1) {
          state.users[responderIndex].isActive = true
          return;
        }

        // остаюсь только я
        state.users[0].isActive = true;
      }

    },
    unpinUser: (state, {payload}: PayloadAction<any>) => {
      const index = state.users.findIndex((v: User.IUsersTo) => v.id === payload);
      if (index > -1) {
        state.users[index] = {...state.users[index], isPinned: false}
      }
    },
    updateUserPinned: (state, {payload}: PayloadAction<any>) => {
      const pinnedActive = state.users.findIndex((v: User.IUsersTo) => v.isPinned === true);
      if (pinnedActive > -1) {
        state.users[pinnedActive] = {...state.users[pinnedActive], isPinned: false}
      }

      const index = state.users.findIndex((v: User.IUsersTo) => v.id === parseInt(payload.id));
      if (index > -1) {
        state.users[index].isPinned = true;
      }
    },
    updateUserLocal: (state, {payload}: PayloadAction<any>) => {
      const indexLocal = state.users.findIndex((v: User.IUsersTo) => v.isLocal === true);
      state.users[indexLocal] = {...state.users[indexLocal], ...payload};
    },
    cancelUser: (state, {payload}: PayloadAction<any>) => {
      const index = state.users.findIndex((v: User.IUsersTo) => v.id === parseInt(payload));
      state.users[index] = {...state.users[index], key: USERS.CANCELLED, isActive: false};
    },
    restartUser: (state, {payload}: PayloadAction<any>) => {
      const index = state.users.findIndex((v: User.IUsersTo) => v.id === parseInt(payload.id));

      state.users[index] = {...payload, key: USERS.CALLING, isActive: false};
    },
    transferToMeet: (state, {payload}: PayloadAction<any>) => {
      const index = state.users.findIndex((v: User.IUsersTo) => v.id === parseInt(payload.id));

      state.users[index] = {...payload, key: USERS.IN_MEETING, isActive: false};
    },
    makeHost: (state, {payload}: PayloadAction<any>) => {
      const indexActive = state.users.findIndex((v: User.IUsersTo) => v.isHost === true);
      if (indexActive > -1) state.users[indexActive].isHost = false;

      const index = state.users.findIndex((v: User.IUsersTo) => v.id === parseInt(payload.id));
      if (index > -1) state.users[index].isHost = true;
    }
  },
  extraReducers: (builder) => {
  }
})

export const {
  setUsers, addUser,
  updateUser,
  updateUserPinned,
  updateUserLocal,
  deleteUser, cancelUser,
  restartUser, makeHost,
  transferToMeet,
  unpinUser, setProfile,
  setNowAtMeet
} = usersSlice.actions;
export default usersSlice.reducer;
