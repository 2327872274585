import React, { useEffect, useRef } from "react";
import PageWrap from "../wrap";
import WaitingRoomVideo from "./WaitingRoomVideo";
import WaitingUserInput from "./WaitingUserInput";
import "./waiting.scss";
import { Layout } from "@clout-team/web-components";
import SelectDevices from "./SelectDevices/SelectDevices";
import Privacy from "./Privacy/Privacy";
import DialogsConfirm from "../../components/DialogsConfirm/DialogsConfirm";
import Profile from "./Profile/Profile";
import {calcVideoSize} from "../../helpers/functions";
let observer: ResizeObserver | null = null;

window.waitingStream = {
  aInternal: null,
  aListener: function(val) {},
  set stream(val) {
    this.aInternal = val;
    this.aListener(val);
  },
  get stream() {
    return this.aInternal;
  },
  registerListener: function(listener) {
    this.aListener = listener;
  }
}

const PageWaiting = () => {
  const ref: any = useRef();

  useEffect(() => {
    const block = ref.current,
      video = document.getElementById("waiting-video-responsive");
    if (block && video) {
      const resizeHandle = (entries: any) => {
        window.requestAnimationFrame(() => {
          for (const entry of entries) {
            const width = entry.borderBoxSize
              ? entry.borderBoxSize[0].inlineSize
              : entry.contentRect.width,
              height = entry.borderBoxSize
                ? entry.borderBoxSize[0].blockSize
                : entry.contentRect.height;
            if (window.innerWidth > 1253) {
              video.removeAttribute("style");
              return;
            }

            const size = calcVideoSize(width, height);

            video.style.width = `${size.width}px`;
            video.style.height = `${size.height}px`;
          }
        })
      };

      observer = new ResizeObserver(resizeHandle);
      observer.observe(block);
    }

    return () => {
      if (observer) {
        observer.disconnect();
        observer = null;
      }
    };
  }, []);

  return (
    <PageWrap headerRight={<Profile />}>
      <div className="waiting-page">
        <div className="page-content-wrap">
          <Layout gap="lg" className="waiting-page-layout" isAlignCenter>
            <div className="waiting-page-left" ref={ref}>
              <WaitingRoomVideo />
              <SelectDevices />
            </div>
            <div className="waiting-page-right">
              <div className="waiting-page-right-content">
                <WaitingUserInput />
              </div>
            </div>
          </Layout>
        </div>
        <div className="waiting-page-privacy">
          <Privacy />
        </div>
        <DialogsConfirm />
      </div>
    </PageWrap>
  );
};

export default PageWaiting;
