import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  Button,
  Input,
  Text,
  Layout,
  Loading,
  Checkbox,
  TooltipWrap,
} from "@clout-team/web-components";
import {useDispatch, useSelector} from "react-redux";
import {setProfile, updateUserLocal} from "../../../redux/slices/users";
import {maxLimitForContentEditableDiv} from "@clout-team/helpers/dist/web/html";
import {
  BRAND_NAME,
  LAND_PAGE,
  MEET_BRAND_NAME
} from "../../../constants/contants";
import {userProfileSelector} from "../../../redux/selectors/users";
import {
  uiSelectorIsAwaitHostConfirm,
  uiSelectorIsAwaitReady,
  uiSelectorIsAwaitStart,
} from "../../../redux/selectors/ui";
import {setIsAwaitReady} from "../../../redux/slices/ui";
import {conferenceHashSelector} from "../../../redux/selectors/conference";
import {emitEvent, reConnect} from "../../../helpers/socketHelper";
import {
  setConference,
} from "../../../redux/slices/conferense";
import {settingsSelectorIsRememberUserName} from "../../../redux/selectors/settings";
import {setIsRememberUserName} from "../../../redux/slices/settings";
import EditFilled from "@clout-team/icons/react/EditFilled";
import Checkmark from "@clout-team/icons/react/Checkmark";
import WaitingUsers from "../WaitingUsers/WaitingUsers";
import {getValidatedString} from "../../../utils/functions";
import {useNavigate} from "react-router-dom";
import {formatUserName, getToken, isShowLoading, selectText} from "../../../helpers/functions";
import {profileGetSync, registerUser} from "../../../api/calls";
import callStore from "../../../redux/stores/callStore";
import {meetLog} from "../../../helpers/log";
import {Cookies} from "react-cookie";
import { roomBehavior } from "../../../helpers/callsHelper";

const cookies = new Cookies();


const WaitingUserInput = () => {
  const lang = useSelector((state: any) => state.localization);
  const L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]),
    hash = useSelector(conferenceHashSelector),
    isAwaitStart = useSelector(uiSelectorIsAwaitStart),
    isAwaitHostConfirm = useSelector(uiSelectorIsAwaitHostConfirm),
    isAwaitReady = useSelector(uiSelectorIsAwaitReady),
    profile = useSelector(userProfileSelector),
    isRememberUserName = useSelector(settingsSelectorIsRememberUserName)

  const dispatch = useDispatch(),
    [isLoading, setIsLoading] = useState<boolean>(false),
    [error, setError] = useState(""),
    [userName, setUserName] = useState<string>(""),
    [edited, setEdited] = useState<boolean>(false),
    [meetName, setMeetName] = useState<string>(L("calls.login_in_to_meeting")),
    [meetTitle, setMeetTitle] = useState<string>(
      `${L("calls.meeting_in")} ${MEET_BRAND_NAME}`
    ),
    navigate = useNavigate();

  useEffect(() => {
    if (meetName !== L("calls.login_in_to_meeting")) {
      document.title = `${meetName} | ${BRAND_NAME} ${L('email.label.conference')?.toLowerCase()}`;
    }
  }, [meetName])

  useEffect(() => {
    dispatch(setIsAwaitReady(false));

    const goHandle = (e: any) => {
      navigate(`/${e.detail}`);
    };

    const meetTitleHandler = (e: any) => {
      setMeetName(e.detail)
    };

    const navigateHandler = (e: any) => {
      navigate(e.detail);
    };

    setTimeout(() => {
      isShowLoading(false);
    }, 500)

    document.addEventListener("go-home", goHandle);
    document.addEventListener("set-title", meetTitleHandler);
    document.addEventListener("navigate", navigateHandler);
    return () => {
      document.removeEventListener("go-home", goHandle);
      document.removeEventListener("set-title", meetTitleHandler);
      document.removeEventListener("navigate", navigateHandler);
    };
  }, []);

  useEffect(() => {
    dispatch(setConference({title: meetTitle}));
  }, [meetTitle])

  useEffect(() => {
    if (hash) {
      emitEvent({
        'event': 'info',
        'link': hash,
        'tmp_key': 'title',
      })
    }
  }, [hash])

  useEffect(() => {
    if (profile && !isAwaitReady) {
      setUserName(formatUserName(profile));
      dispatch(updateUserLocal({name: formatUserName(profile)}));
    }
  }, [profile, isAwaitReady, dispatch]);

  useEffect(() => {
    if (edited) {
      const divContent: any = document.getElementById("editable-title");
      if (divContent) {
        selectText(divContent);
        divContent.focus();
      }
    }
  }, [edited]);

  const joinHandle = useCallback(async () => {
    const input: any = document.getElementById("waiting-user-input");
    if (input && input.value.trim().length === 0) {
      setError(L("calls.the_field_must_be_filled"));
      return;
    }
    setIsLoading(true);


    const token = getToken();
    if (token) {
      roomBehavior();
    } else {
      // регистрируем юзера
      await registerUser(input ? input.value : "").then(async (res) => {
        if (res?.response?.token) {
          cookies.set("token", String(res.response.token), {
            maxAge: 2678400,
            domain:
              window.location.host.includes('localhost')
                ? "localhost"
                : ".lenzaos.com",
          });

          const profile: any = profileGetSync();
          if (profile && profile.response && !profile.response.headers) {
            callStore.dispatch(setProfile(profile.response));
          }

          const create = async () => {
            roomBehavior();
          };
          reConnect(create);
        } else {
          // TODO обработать ошибку что не удалось зарегистрировать юзера
          meetLog("не удалось зарегистрировать юзера");
        }
      });
    }
  }, [hash, L, meetTitle, dispatch]);

  const inputChange = useCallback(
    (value: string) => {
      if (error) setError("");
      dispatch(updateUserLocal({name: value}));
    },
    [dispatch, error]
  );

  const renderInput = useMemo(() => {
    return (
      <Input
        autoComplete={"off"}
        maxLength={48}
        id={"waiting-user-input"}
        className={"no-dismiss rounded-corners-8 inverted-color"}
        onChange={(e) => inputChange(e.target.value)}
        label={L("calls.waiting.full.name")}
        placeholder={L("calls.waiting.full.name.enter")}
        value={userName}
        errorMessage={error}
      />
    );
  }, [userName, inputChange, L, error]);

  const renderButton = useMemo(() => {
    return (
      <Button
        loading={isLoading}
        className={"rounded-corners-8 new-meeting-button"}
        onClick={joinHandle}
        size={"lg"}
      >
        {hash
          ? L("calls.enter_the_meeting")
          : L("conference.button.start_meeting")}
      </Button>
    );
  }, [hash, joinHandle, isLoading, L]);

  const renderAwait = useMemo(() => {
    if (isAwaitReady) {
      return (
        <>
          <Text size={"dialog-title"} align={"center"}>
            {L("calls.getting_ready")}
          </Text>
          <Text>{L("calls.you_be_able_to_join_in_just_a_moment")}</Text>
        </>
      );
    } else {
      return (
        <Text size={"dialog-title"} align={"center"}>
          {isAwaitStart
            ? L("calls.wait_until_the_organizer_starts")
            : L("calls.please_wait_the_meeting_host_will_let")}
        </Text>
      );
    }
  }, [isAwaitStart, isAwaitReady, L]);

  const renderCheckbox = useMemo(() => {
    return (
      <Checkbox
        id={"remember-name"}
        onChange={(e) => {
          dispatch(setIsRememberUserName(e.target.checked));
        }}
        isChecked={isRememberUserName}
        title={L("calls.remember_my_name")}
      />
    );
  }, [L, dispatch, isRememberUserName]);

  const titleClickHandle = useCallback((e) => {
    setEdited(true);
  }, []);

  const onBlurHandle = useCallback(
    (e) => {
      if (e.target.innerText.trim().length === 0) {
        setMeetTitle(`${L("calls.meeting_in")} ${MEET_BRAND_NAME}`);
      } else {
        setMeetTitle(e.target.innerText.trim());
      }
      setEdited(false);
    },
    [L]
  );

  const onChangeHandle = useCallback((e) => {

  }, []);

  const onKeyDownHandle = useCallback((e) => {
    maxLimitForContentEditableDiv(e, 48);
    if (e.key === "Enter") {
      setMeetTitle(e.target.innerText.trim());
      setEdited(false);
    }
  }, []);

  const pasteHandle = useCallback((e: any) => {
    maxLimitForContentEditableDiv(e, 48);
    const items = e.clipboardData.items
    if (items[0].kind === 'file' && items[0].type.match(/^image/)) {
      return;
    } else if (items[0].kind === 'string') {
      const pattern = e.target.pattern
      let text = e.clipboardData.getData('text')
      if (pattern) text = getValidatedString(text, pattern)
      document.execCommand('insertHtml', false, text)
    }
  }, [])

  const renderTitle = useMemo(() => {
    if (!hash) {
      if (edited) {
        return (
          <TooltipWrap hint={L("calls.press_enter_to_save")}>
            <Layout gap="sm" className="icon-blue full-width editable-layout">
              <div
                id="editable-title"
                onBlur={onBlurHandle}
                onInput={onChangeHandle}
                onKeyDown={onKeyDownHandle}
                className="content-edit-title"
                contentEditable={true}
                suppressContentEditableWarning={true}
                onPaste={pasteHandle}
                placeholder={`${L("calls.the_name_of_the_meet")}`}
              >
                {meetTitle}
              </div>
              <Checkmark/>
            </Layout>
          </TooltipWrap>
        );
      } else {
        return (
          <div
            className="clickable-meet-title"
            id="clickable-title"
            onClick={titleClickHandle}
          >
            <TooltipWrap hint={L("calls.change_the_name_of_the_meet")}>
              <Layout gap="sm" isAlignCenter>
                <span>{meetTitle}</span>
                <EditFilled/>
              </Layout>
            </TooltipWrap>
          </div>
        );
      }
    } else {
      return (
        <Text size={"dialog-title"} align={"center"}>
          {meetName}
        </Text>
      );
    }
  }, [L, edited, hash, meetTitle, onBlurHandle, onChangeHandle, onKeyDownHandle, pasteHandle, meetName, titleClickHandle]);

  const renderButtonLeave = useMemo(() => {
    return (
      <Button
        className="rounded-corners-8"
        color="white"
        isStroke
        onClick={(e) => {
          document.location.href = LAND_PAGE;
        }}
      >
        {L("calls.leave_the_meeting")}
      </Button>
    );
  }, [L]);

  return (
    <>
      {isAwaitStart || isAwaitHostConfirm || isAwaitReady ? (
        <Layout gap={"sm"} vertical isAlignCenter>
          {renderAwait}
          <Loading size={"sm"} isNewDesign/>
          {isAwaitStart || isAwaitHostConfirm ? renderButtonLeave : <></>}
        </Layout>
      ) : (
        <Layout gap={"lg"} vertical>
          {renderTitle}
          {profile ? (
            <WaitingUsers/>
          ) : (
            <Layout gap={"sm"} vertical>
              {renderInput}
              {renderCheckbox}
            </Layout>
          )}
          {renderButton}
        </Layout>
      )}
    </>
  );
};

export default WaitingUserInput;
