export const API_URL: string = String(process.env.REACT_APP_API_URL);
export const BASE_URL = `${process.env.REACT_APP_API_URL}`;
export const API_JANUS: string = String(process.env.REACT_APP_API_JANUS);
export const SOCKET_JANUS: string = String(process.env.REACT_APP_SOCKET_JANUS);
export const SOCKET_VERSION: string = String(process.env.REACT_APP_SOCKET_VERSION);

export const SOCKET_CHANNEL = 'meeting'
export const SOCKET_CUSTOM = 'custom'
export const SOCKET_PROFILE = 'profile'

export const BROWSERS = {
  CHROME: 'Chrome',
  SAFARI: 'Safari',
  EDGE: 'Microsoft Edge',
  YANDEX: 'Yandex',
  FIREFOX: 'Firefox'
}

export const CONTEXT_TYPES = {
  PARTICIPANT_MORE: 'participant-more',
  CHAT_MORE: 'chat-more',
  USER_SETTINGS: 'user-settings'
}

export const SHARING_BITRATE = parseInt(process.env.REACT_APP_SHARING_BITRATE + "") || 2432000;
export const SHRAING_FRQ = parseInt(process.env.REACT_APP_SHRAING_FRQ + "") || 30;

export const WINDOW_SIZE = {
  S: [280, 262],
  XS: [280, 103]
}

export const THEMES = {
  WHITE_SOFT: 'white-soft',
  WHITE_BRIGHT: 'white-bright',
  DARK_BRIGHT: 'dark-bright',
  DARK_SOFT: 'dark-soft'
}

export const RIGHT_BAR_TYPES = {
  COMMENTS: 'comments',
  PARTICIPANTS: 'participants'
}

export const LAYOUTS = {
  SPOTLIGHT: 'spotlight',
  GRID: 'grid',
  STACK: 'stack',
  SIDE_BY_SIDE: 'side_by_side',
}

export const SHARE_ROLES = {
  PUBLISHER: 'publisher',
  LISTENER: 'listener',
}

export const ERROR_TYPES = {
  PERMISSION_SHARE: 'permission_share',
  CALL_END: 'call_end',
}

export const RESOLUTION = {
  WIDTH: 1920,
  HEIGHT: 1080
}

export const FRAME_RATE = 20

export const SETTINGS_NAMES = {
  PIN_CALL_UI: 'pinCallUi',
}

export const ALERT_TYPES = {
  FULL_SCREEN: 'full-screen',
  GET_HOST: 'get-host',
  HOST_MUTE_USER: 'host-mute-user',
  HOST_TURN_OFF_VIDEO: 'host-turn-off-video',
  ME_TURN_OFF_VIDEO: 'me-turn-off-video',
  I_MUTED: 'i-muted',
  LINK_COPIED: "link-copied"
}

export const ROUT_TYPES = {
  GUEST: 'guest',
  FINISH: 'finish',
  ERROR_LINK: 'error_link'
}

export const PLUG_TYPES = {
  YOU_LEFT_MEET: 'you_left_meet',
  MEETING_OVER: 'meeting_over',
  MEETING_OVER_HOST: 'meeting_over_host',
  I_WAS_KICK: 'i_was_kick',
  REJECT_ENTRY: 'reject_entry',
  MEETING_OVER_ENTRY: 'meeting_over_entry'
}

export const DIALOG_CONFIRM = {
  MUTE_USER: 'mute',
  TURN_OFF_VIDEO: 'turn_off_video',
  REMOVE_FROM_MEETING: 'remove_from_meeting',
  SET_PERMISSIONS: 'set_permissions',

  CHOOSE_THEME: 'choose_theme',
  RENAME_MEETING: 'rename_meeting',
  PROFILE_INFO: 'profile_info'
}

export const BRAND_NAME = 'Lenza'
export const MEET_BRAND_NAME = 'Lenza Meet'

export const ROOM_TYPES = {
  PRIVATE: 'PRIVATE',
  CONFERENCE: 'CONFERENCE',
}

export const USER_AGREEMENT_LINK = 'https://lenzaos.com/agreement/'
export const PRIVACY_LINK = 'https://lenzaos.com/privacy/'
export const DOMAIN = window.location.origin;
export const DOMAIN_NAME = `${DOMAIN}/`.replace(/^(http(s)?\:\/\/)/, "")
export const LAND_PAGE = `${process.env.REACT_APP_LAND_PAGE}`

export const ERROR_LINK = `${DOMAIN_NAME}xxx-yyyy-zzz-cc`
export const AUTH_URL = `${process.env.REACT_APP_AUTH_URL}`

export const REFERRERS = {
  CHATS: 'chat',
  SPACES: 'spaces',
  CONTACTS: 'contacts'
}

export const PARTICIPANT_STATUS = {
  NONE: 'none',
  CALLING: 'calling',
  CONNECTING: 'connecting'
}

// LOCALSTORAGE CONSTANTS
export const STORAGE_PREV_DEVICE_ID = 'c60306dd-8065-4555-b4fa-b54d41f0f817'
