import classNames from "classnames";
import React, {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {settingsSelectorActiveDevices, settingsSelectorConferenceSettings} from "../../redux/selectors/settings";
import {setAlertType, setConfirmType, setContextType, setShowInviteUsers} from "../../redux/slices/ui";
import {BaseContextMenu} from "@clout-team/web-components";

// icons region
import Video from '@clout-team/icons/react/Video'
import PersonAdd from '@clout-team/icons/react/PersonAdd'
import Checkmark from '@clout-team/icons/react/Checkmark';
import Link from '@clout-team/icons/react/Link';
import Snooze from '@clout-team/icons/react/Snooze';
import ShareScreen from '@clout-team/icons/react/ShareScreen';
import Edit from '@clout-team/icons/react/Edit';
import DarkTheme from '@clout-team/icons/react/DarkTheme';

import './UserSettings.scss'
import {conferenceHashSelector, conferenceLinkSelector} from "../../redux/selectors/conference";
import {ALERT_TYPES, DIALOG_CONFIRM} from "../../constants/contants";
import {userCurrentSelector, usersWaitingSelector} from "../../redux/selectors/users";
import {
  devicesSelectorAudioDevices,
  devicesSelectorSpeakersDevices,
  devicesSelectorVideoDevices
} from "../../redux/selectors/devices";
import {Emit} from "../../helpers/socketHelper";
import {SOCKET_ACTIONS} from "../../constants/socket";
import {deleteUserAsync} from "../../redux/slices/users";
import {handleChangeDevice} from "../../helpers/callsHelper";
import {copyClipboard} from "../../helpers/functions";

export enum ACTION_KEYS {
  COPY_LINK = 'COPY-LINK',
  INVITE_PEOPLE = 'INVITE-PEOPLE',

  CHANGE_DEVICE_AUDIO = 'CHANGE-DEVICE-AUDIO',
  CHANGE_DEVICE_SPEAKER = 'CHANGE-DEVICE-SPEAKER',
  CHANGE_DEVICE_VIDEO = 'CHANGE-DEVICE-VIDEO',

  CAMERA_BACKGROUND = 'CAMERA-BACKGROUND',
  BLUR_MY_BACKGROUND = 'BLUR_MY_BACKGROUND',

  WAITING_ROOM = 'WAITING_ROOM',
  EVERYONE_CAN_SHARE = 'EVERYONE_CAN_SHARE',
  EVERYONE_CAN_CHAT = 'EVERYONE_CAN_CHAT',
  SHOW_CHAT_PREVIEWS = 'SHOW_CHAT_PREVIEWS',

  RENAME_MEETING = 'RENAME_MEETING',
  CHOOSE_THEME = 'CHOOSE_THEME'

}

const UserSettings = (props: {
  element: HTMLElement;
}) => {
  const {element} = props;

  const dispatch = useDispatch();

  const lang = useSelector((state: any) => state.localization),
    link = useSelector(conferenceLinkSelector),
    hash = useSelector(conferenceHashSelector),
    L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]),
    videoDevices = useSelector(devicesSelectorVideoDevices),
    audioDevices = useSelector(devicesSelectorAudioDevices),
    speakersDevices = useSelector(devicesSelectorSpeakersDevices),
    activeDevices = useSelector(settingsSelectorActiveDevices),
    // blurMyBG = useSelector(settingsSelectorBlurMyBackground),
    conferenceSettings = useSelector(settingsSelectorConferenceSettings)
  const localUser = useSelector(userCurrentSelector);
  const waitingUsers = useSelector(usersWaitingSelector)

  const closeContextHandle = useCallback(() => {
    dispatch(setContextType({type: ''}))
    element?.classList?.remove("btn--active")
  }, [])

  const audioInputDevices = useMemo(() => {
    return audioDevices
      ?.map((device: any) => ({
        ...(device ?? {}),
        device: device,
        title: device?.label,
        active: activeDevices?.audio === device.deviceId
      }))
  }, [activeDevices?.audio, audioDevices])
  const videoInputDevices = useMemo(() => {
    return videoDevices
      ?.map((device: any) => ({
        ...(device ?? {}),
        device: device,
        title: device?.label,
        active: activeDevices?.video === device.deviceId
      }))
  }, [activeDevices?.video, videoDevices])
  const audioOutputDevices = useMemo(() => {
    return speakersDevices
      ?.map((device: any) => ({
        ...(device ?? {}),
        device: device,
        title: device?.label,
        key: device.deviceId,
        active: activeDevices?.speaker === device.deviceId
      }))
  }, [activeDevices?.speaker, speakersDevices])

  const conferenceTopActions = useMemo(() => {
    return [
      {
        title: L("reg.title.invite_people"),
        left: <PersonAdd/>,
        active: false,
        action_key: ACTION_KEYS.INVITE_PEOPLE
      },
      {
        title: L("conference.button.copy_invite_link"),
        left: <Link/>,
        active: false,
        action_key: ACTION_KEYS.COPY_LINK
      },
    ]
  }, [L])
  const devicesList = useMemo(() => {
    return [
      {
        tab: 0,
        title: L("calls.label.select_a_camera"),
        content: videoInputDevices,
        onItem: (e) => {
          handleChangeDevice(e.device, ACTION_KEYS.CHANGE_DEVICE_VIDEO)
        },
      },
      {
        tab: 0,
        title: L("calls.label.select_a_microphone"),
        content: audioInputDevices,
        onItem: (e) => {
          handleChangeDevice(e.device, ACTION_KEYS.CHANGE_DEVICE_AUDIO)
        },
      },
      {
        tab: 0,
        title: L("calls.label.select_a_speaker"),
        content: audioOutputDevices,
        onItem: (e) => {
          handleChangeDevice(e.device, ACTION_KEYS.CHANGE_DEVICE_SPEAKER)
        },
      },
    ]
  }, [L, videoInputDevices, audioInputDevices, audioOutputDevices])

  const userDevicesSettings = useMemo(() => {
    return [
      {
        title: L("calls.label.video_and_mic_settings"),
        left: <Video/>,
        chevronRight: true,
        submenu: {
          className: "settings-context-menu--more",
          lists: devicesList
        },
      }/*,
      {
        title: L("calls.label.camera_background"),
        left: <VideoBackgroundEffect />,
        // active: false,
        rightItem: <Checkmark />,
        action_key: ACTION_KEYS.CAMERA_BACKGROUND
      },
      {
        title: L("calls.label.blur_my_background"),
        left: <Drop />,
        active: false,
        action_key: ACTION_KEYS.BLUR_MY_BACKGROUND
      },*/
    ]
  }, [L, devicesList])
  const conferenceAccessSettings = useMemo(() => {
    let ar: any = [
      // {
      //   title: L("calls.label.show_chat_previews"),
      //   left: <Comment/>,
      //   active: conferenceSettings.showChatPreviews,
      //   action_key: ACTION_KEYS.SHOW_CHAT_PREVIEWS
      // }
    ]

    if (localUser.isHost) {
      ar.unshift({
          title: L("calls.label.waiting_room"),
          left: <Snooze/>,
          action_key: ACTION_KEYS.WAITING_ROOM,
          active: conferenceSettings.waitingRoom
        },
        {
          title: L("calls.label.everyone_can_share"),
          left: <ShareScreen/>,
          action_key: ACTION_KEYS.EVERYONE_CAN_SHARE,
          active: conferenceSettings.everyoneCanShare
        },
        // {
        //   title: L("calls.label.everyone_can_chat"),
        //   left: <PersonFeedback/>,
        //   active: conferenceSettings.everyoneCanChat,
        //   action_key: ACTION_KEYS.EVERYONE_CAN_CHAT
        // }
      )
    }

    return ar
  }, [L, conferenceSettings.everyoneCanChat, conferenceSettings.everyoneCanShare, conferenceSettings.showChatPreviews, conferenceSettings.waitingRoom, localUser.isHost])
  const aditionalActions = useMemo(() => {
    let ar = [{
      title: L("calls.label.choose_a_theme"),
      left: <DarkTheme/>,
      rightItem: <Checkmark/>,
      action_key: ACTION_KEYS.CHOOSE_THEME
    }];

    if (localUser.isHost)
      ar.unshift({
        title: L("calls.label.rename_meeting"),
        left: <Edit/>,
        rightItem: <Checkmark/>,
        action_key: ACTION_KEYS.RENAME_MEETING
      })
    return ar
  }, [L, localUser.isHost])


  const contextMenu = useMemo(() => {
    return <BaseContextMenu
      isModal={true}
      alignToElement={element}
      onClose={closeContextHandle}
      className={classNames({
        "settings-context-menu": true
      })}
      lists={[
        {
          tab: 0,
          content: conferenceTopActions,
          onItem: (e) => {
            switch (e.action_key) {
              case ACTION_KEYS.COPY_LINK:
                dispatch(setAlertType({
                  type: ALERT_TYPES.LINK_COPIED
                }))
                copyClipboard(link)
                break;
              case ACTION_KEYS.INVITE_PEOPLE:
                dispatch(setShowInviteUsers(true))
                break;
            }
          }
        },
        {
          tab: 0,
          content: userDevicesSettings,
          onItem: (e) => {
            if (e.action_key === ACTION_KEYS.BLUR_MY_BACKGROUND) {
              // dispatch()
            }
            return;
          }
        },
        {
          tab: 0,
          content: conferenceAccessSettings,
          onItem: (e) => {
            switch (e.action_key) {
              case ACTION_KEYS.SHOW_CHAT_PREVIEWS:
                break;
              case ACTION_KEYS.WAITING_ROOM:
                if (e?.active) {
                  Emit(SOCKET_ACTIONS.MEETING_DISABLE_CONFIRM_AUTH, {
                    link: hash
                  })

                  waitingUsers.forEach(u => {
                    Emit(SOCKET_ACTIONS.MEETING_ACCEPT_ENTRY, {link: hash, id: u.waitingId})
                    dispatch(deleteUserAsync(u.id))
                  })
                } else {
                  Emit(SOCKET_ACTIONS.MEETING_ENABLE_CONFIRM_AUTH, {
                    link: hash
                  })
                }
                break;
              case ACTION_KEYS.EVERYONE_CAN_CHAT:
                if (e?.active) {
                  Emit(SOCKET_ACTIONS.MEETING_DISABLE_CHAT, {
                    link: hash
                  })
                } else {
                  Emit(SOCKET_ACTIONS.MEETING_ENABLE_CHAT, {
                    link: hash
                  })
                }
                break;
              case ACTION_KEYS.EVERYONE_CAN_SHARE:
                if (e?.active) {
                  Emit(SOCKET_ACTIONS.MEETING_DISABLE_SHARE, {
                    link: hash
                  })
                } else {
                  Emit(SOCKET_ACTIONS.MEETING_ENABLE_SHARE, {
                    link: hash
                  })
                }
                break;
            }
          }
        },
        {
          tab: 0,
          content: aditionalActions,
          onItem: (e) => {
            switch (e.action_key) {
              case ACTION_KEYS.RENAME_MEETING:
                dispatch(setConfirmType({
                  type: DIALOG_CONFIRM.RENAME_MEETING
                }))
                break;
              case ACTION_KEYS.CHOOSE_THEME:
                dispatch(setConfirmType({
                  type: DIALOG_CONFIRM.CHOOSE_THEME
                }))
                break;
            }
          }
        },
      ]}
    />
  }, [waitingUsers, element, closeContextHandle, conferenceTopActions, userDevicesSettings, conferenceAccessSettings, aditionalActions, dispatch, link, hash])

  return (
    <>
      {contextMenu}
    </>
  );
}

export default React.memo(UserSettings)
