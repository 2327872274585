import {io} from "socket.io-client";
import {endCall, rejectCall} from "./callsHelper";
import {SOCKET_EVENTS, SOCKET_ACTIONS} from "../constants/socket";
import {
  SOCKET_CHANNEL, API_URL,
  ALERT_TYPES,
  ROUT_TYPES, PLUG_TYPES, SOCKET_VERSION, SOCKET_CUSTOM, SOCKET_PROFILE
} from "../constants/contants";
import callStore from "../redux/stores/callStore";
import {User} from "../interfaces/user";
import tukAudio from "../audio/guest_tuk.mp3";
import {getMe} from "../functions/users";
import {
  updateUser,
  makeHost,
  setUsers,
  setUserAsync,
  updateUserLocal,
  toggleMuteAsync, deleteUserAsync,
  toggleVideoAsync, setNowAtMeet
} from "../redux/slices/users";
import {setConference, setSubject} from "../redux/slices/conferense";
import {setLocale} from "../redux/localization/actions";

import {
  setAlertType,
  setRout,
  setIsAwaitHostConfirm,
  setIsShowWaitingEntry, setIsAwaitReady, setIsAwaitStart, setFromWaiting
} from "../redux/slices/ui";
import {addUser} from "../redux/slices/debug";
import {batch} from "react-redux";
import {USERS} from "../constants/meeting";
import {destroyConference} from "./common";
import {setConferenceSettings} from "../redux/slices/settings";
import {JanusSingleton} from "../janus/singleton";
import {formatUserName, getToken, playSounds, removeStream} from "./functions";
import {meetLog} from "./log";
import {Cookies} from "react-cookie";

const cookies = new Cookies();
let timeOutConnect: ReturnType<typeof setTimeout>, joined = false;

let socket = io(API_URL, {
  query: {
    token: cookies.get('token'),
  },
  path: '/socket_1/',
  reconnectionDelay: 500,
  reconnectionDelayMax: 1000,
  randomizationFactor: 0,
  autoConnect: true,
  reconnection: true,
  reconnectionAttempts: Infinity,
  jsonp: true,
  agent: false,
  upgrade: false,
  rejectUnauthorized: false,
  rememberUpgrade: false,
  // @ts-ignore
  perMessageDeflate: false,
  transports: ['websocket'],
  closeOnBeforeunload: false
});

socket.on("connect", () => {
  meetLog('connect');
  // @ts-expect-error
  window.soketIsReady = true

  // если ранее уже подключался
  if (joined) {
    JanusSingleton.reconnect()
    JanusSingleton.iceRestart()
  }
});

socket.on("disconnect", () => {
  meetLog("disconnect");
  // @ts-expect-error
  window.soketIsReady = false
});

export const getSocket = () => socket;

export const reConnect = (callback: () => void) => {
  if (socket) {
    socket.disconnect();
    socket.io.opts.query = {
      token: getToken(),
    };
    socket.connect();

    socket.on("connect", () => {
      meetLog('подключен');
      callback();
    });
  }
}

export async function emitEvent(data: any) {
  meetLog('emitEvent', data);
  socket.emit(SOCKET_CHANNEL, data);
}

export async function emitLog(data: any) {
  const urlArr = window.location.href.split('/');
  if (urlArr[3]) data.hash = urlArr[3]
  data.url = window.location.href
  socket.emit('log', {event: 'call_log', data: data})
}

export async function emitCustomEvent(data: any) {
  meetLog('emitCustomEvent', data);

  socket.emit(SOCKET_CUSTOM, data);
}

const checkRecursive = (hash: string) => {
  timeOutConnect = setTimeout(async () => {
    emitEvent({
      'event': 'info',
      'tmp_key': 'checkingStart',
      'link': hash
    })
  }, 5000);
};

export const socketInit = () => {
  let currentCallId: string = '';

  socket.on(SOCKET_PROFILE, async (data: any) => {
    if (data.event === 'profile-set-lang') {
      const lang = data?.data?.lang;
      if (lang) callStore.dispatch(setLocale(lang));
    }
  })

  socket.on(SOCKET_CUSTOM, async (data: any) => {
    meetLog('custom event', data);

    if (data.event === 'leave_meet') {
      const {
        conference: {conference: {hash}},
        meetingUsers: {users}
      } = callStore.getState();

      if (data.link === hash) {
        endCall(users && users.length === 1 && users[0].isHost, PLUG_TYPES.YOU_LEFT_MEET)
        setTimeout(() => window.close(), 1000)
      }
    }
  })

  socket.on(SOCKET_CHANNEL, async (data: any) => {
    if (!data.data?.other?.other?.debugUser)
      meetLog('socket', data);

    if (data.event === SOCKET_EVENTS.MEETING_JOIN) {

      if (!joined) {
        const iAm = getMe(), socketData = data.data;
        const {
          conference: {conference: {permissions}},
        } = callStore.getState();

        batch(() => {
          if (iAm && iAm.key === USERS.GUEST) {
            if (window.waitingStream.stream) {
              removeStream(window.waitingStream.stream);
              window.waitingStream.stream = null;
            }
            callStore.dispatch(setRout(''));
            document.dispatchEvent(new CustomEvent('go-home', {detail: data.data.link}));
          }

          callStore.dispatch(updateUserLocal({
            id: socketData.id,
            key: USERS.IN_MEETING,
            video: !permissions.camera ? false :
              iAm?.key === USERS.GUEST ? iAm?.video : socketData.confirm_auth
          }));

          callStore.dispatch(setConference({
            token: socketData.token,
            title: socketData.title
          }));

          callStore.dispatch(setConferenceSettings({
            waitingRoom: socketData.confirm_auth
          }))

          // confirm_auth

          // if (socketData.is_call) {
          //   callStore.dispatch(setLayout(LAYOUTS.SPOTLIGHT))
          // } else {
          //  callStore.dispatch(setLayout(socketData.participants.length > 2 ? LAYOUTS.GRID : LAYOUTS.SPOTLIGHT))
          // }
        })

        if (data?.thread_hash) {
          callStore.dispatch(setSubject(data?.thread_hash));
        }

        currentCallId = socketData.link;
        JanusSingleton.joinToJanus(socketData)
        joined = true;
      }
    }

    if (data.event === SOCKET_EVENTS.MEETING_CALL_END) {
      endCall(true, PLUG_TYPES.MEETING_OVER)
    }

    if (data.event === SOCKET_EVENTS.MEETING_CLOSE) {
      const store: any = callStore.getState(),
        isAwaitHostConfirm = store.ui.isAwaitHostConfirm

      if (isAwaitHostConfirm) {
        destroyConference(PLUG_TYPES.MEETING_OVER_ENTRY);
      } else {
        destroyConference(PLUG_TYPES.MEETING_OVER_HOST);
      }
      callStore.dispatch(setUsers([]));
      JanusSingleton.destroy();
    }

    if (data.event === SOCKET_EVENTS.MEETING_REJECT) {
      rejectCall();
    }

    if (data.event === SOCKET_EVENTS.MEETING_CUSTOM) {
      if (data.data && data.data.other && data.data.other.other) {
        const store: any = callStore.getState();
        const users = store.meetingUsers.users;
        const iAm = getMe();
        const customData = data.data.other.other

        // мне хост отключил микрофон
        if (customData.turn_audio && customData.userId) {
          if (iAm && iAm.id === parseInt(customData.userId)) {
            const host = users.find((v: User.IUsersTo) => v.isHost === true)
            if (host) {
              batch(() => {
                callStore.dispatch(setAlertType({
                  type: ALERT_TYPES.I_MUTED,
                  data: host
                }))
                callStore.dispatch(toggleMuteAsync(iAm));
              })
            }
          }
        }

        // мне хост отключил камеру
        if (customData.turn_video && customData.userId) {
          if (iAm && iAm.id === parseInt(customData.userId)) {
            const host = users.find((v: User.IUsersTo) => v.isHost === true)
            if (host) {
              batch(() => {
                callStore.dispatch(setAlertType({
                  type: ALERT_TYPES.ME_TURN_OFF_VIDEO,
                  data: host
                }))
                callStore.dispatch(toggleVideoAsync(iAm));
              })
            }
          }
        }

        // для дебага юзер мне дал свои данные
        if (window.location.host !== 'meet.lenzaos.com' && window.location.host !== 'app.meet.lenzaos.com') {
          if (customData.debugUser && customData.userId) {
            if (iAm && iAm.id !== parseInt(customData.userId)) {
              callStore.dispatch(addUser(customData.debugUser))

              const debugData = document.getElementById(`pr-other-${customData.userId}`);
              if (debugData) {
                const d = customData.debugUser;
                let text = `User ID: ${d.userId}\r\nRoom: ${d.roomId}\r\n\r\n`
                if (d.devices) {
                  if (d.devices.audio) text += `Audio device: ${d.devices.audio}\r\n`
                  if (d.devices.video) text += `Video device: ${d.devices.video}\r\n`
                  if (d.devices.speaker) text += `Speker device: ${d.devices.speaker}\r\n`
                }

                if (d.video) {
                  text += `\r\nVideo track data:\r\n- Enabled: ${d.video.enabled}\r\n- Label: ${d.video.label}\r\n- Muted: ${d.video.muted}`
                } else {
                  text += `\r\nНет видео дорожки`
                }

                debugData.innerText = text;
              }
            }
          }
        }

      }
    }

    if (data.event === SOCKET_EVENTS.MEETING_NO_ANSWER) {

    }

    if (data.event === SOCKET_EVENTS.MEETING_ROOM_CREATED) {
      if (!data.data.is_call) {
        Emit(SOCKET_ACTIONS.MEETING_JOIN, {link: data.data.link})
      }
    }

    if (data.event === SOCKET_EVENTS.MEETING_USERS) {
      const users = data.data?.participants,
        inMeet = users.filter((u) => u.entered === true);
      if (users) callStore.dispatch(setNowAtMeet(inMeet));
    }

    if (data.event === SOCKET_EVENTS.MEETING_SET_HOST) {
      const iAm = getMe();
      const hash = callStore.getState().conference.conference.hash

      callStore.dispatch(makeHost({id: data.data.id}))
      if (iAm && data.data.id === iAm.id) {
        Emit(SOCKET_ACTIONS.MEETING_GET_ENTRY, {link: hash})

        callStore.dispatch(setAlertType({
          type: ALERT_TYPES.GET_HOST
        }))
        JanusSingleton.changeDisplay()
      }
    }

    if (data.event === SOCKET_EVENTS.MEETING_ERROR) {
      const code = data.data.code;
      if (code === 107) {
        // я не хост чтобы создать комнату по ссылке
        callStore.dispatch(setIsAwaitStart(true));
        checkRecursive(data.data.link);
      }
      if (code === 104) {
        // закрытая конфа ожидайте пока впустит хост
        callStore.dispatch(setIsAwaitHostConfirm(true))
      }

      if (code === 102) {
        callStore.dispatch(setRout(ROUT_TYPES.ERROR_LINK))
      }
    }

    if (data.event === SOCKET_EVENTS.MEETING_KICK) {
      const customData = data.data;
      if (customData.id) {
        const iAm = getMe();
        if (iAm.id === customData.id) {
          endCall(false, PLUG_TYPES.I_WAS_KICK);
        } else {
          callStore.dispatch(deleteUserAsync(customData.id))
        }
      }
    }

    if (data.event === SOCKET_EVENTS.MEETING_LEAVING) {
      const customData = data.data;
      callStore.dispatch(deleteUserAsync(customData.id))
    }

    if (data.event === SOCKET_EVENTS.MEETING_START_TIMER) {

    }

    if (data.event === SOCKET_EVENTS.MEETING_WAITING_ENTRY) {

      // стучаться из комнаты ожидания
      playSounds(tukAudio, 2000);
      const customData = data.data;

      callStore.dispatch(setUserAsync({
        key: USERS.WAITING,
        name: customData.name || ' ',
        surname: customData.surname || ' ',
        avatar: customData.avatar || ' ',
        login: customData.login || ' ',
        domain: customData.domain || ' ',
        cloutId: (customData.login && customData.domain) ? `${customData.login}@${customData.domain}` : ``,
        id: Date.now(),
        waitingId: customData.id,
        answer: false,
        audio: true,
        video: false,
        isHost: false,
        isOwner: false,
        isActive: false,
        lastSpeak: 0,
        isShare: false,
        isLocal: false,
        isPinned: false
      }))
      callStore.dispatch(setIsShowWaitingEntry(true))

    }

    if (data.event === SOCKET_EVENTS.MEETING_ACCEPT_ENTRY) {
      // меня принял хост в конфу
      joined = false;
      Emit(SOCKET_ACTIONS.MEETING_JOIN, {link: data.data.link})
    }

    if (data.event === SOCKET_EVENTS.MEETING_REJECT_ENTRY) {
      // меня не приняли в конфу
      endCall(false, PLUG_TYPES.REJECT_ENTRY);
    }

    if (data.event === SOCKET_EVENTS.MEETING_GET_ENTRY) {
      // ответ со списком ожидающих
      const customData = data.data;
      if (customData && customData.waiting && Array.isArray(customData.waiting) && customData.waiting.length > 0) {
        playSounds(tukAudio, 2000);
        (customData.waiting).forEach(u => {
          callStore.dispatch(setUserAsync({
            key: USERS.WAITING,
            name: u.name || ' ',
            surname: u.surname || ' ',
            avatar: u.avatar || ' ',
            login: u.login || ' ',
            domain: u.domain || ' ',
            cloutId: (u.login && u.domain) ? `${u.login}@${u.domain}` : ``,
            id: Date.now(),
            waitingId: u.id,
            answer: false,
            audio: true,
            video: false,
            isHost: false,
            isOwner: false,
            isActive: false,
            isShare: false,
            isLocal: false,
            isPinned: false,
            lastSpeak: 0
          }))
          callStore.dispatch(setIsShowWaitingEntry(true))
        })
      }
    }

    if (data.event === SOCKET_EVENTS.MEETING_START_AUDIO) {
      const customData = data.data, iAm = getMe();

      if (iAm && iAm.id !== parseInt(customData.id)) {
        callStore.dispatch(updateUser({
          audio: true,
          id: parseInt(customData.id)
        }))
      }
    }

    if (data.event === SOCKET_EVENTS.MEETING_END_AUDIO) {
      const customData = data.data, iAm = getMe();

      if (iAm && iAm.id !== parseInt(customData.id)) {
        callStore.dispatch(updateUser({
          audio: false,
          id: parseInt(customData.id)
        }))
      }
    }

    if (data.event === SOCKET_EVENTS.MEETING_START_VIDEO) {
      const customData = data.data, iAm = getMe();
      if (iAm && iAm.id !== parseInt(customData.id)) {
        callStore.dispatch(updateUser({
          video: true,
          id: parseInt(customData.id)
        }))
      }
    }

    if (data.event === SOCKET_EVENTS.MEETING_END_VIDEO) {
      const customData = data.data, iAm = getMe();
      if (iAm && iAm.id !== parseInt(customData.id)) {
        callStore.dispatch(updateUser({
          video: false,
          id: parseInt(customData.id)
        }))
      }
    }

    if (data.event === SOCKET_EVENTS.MEETING_WAITING_ENTRY_CANCEL) {
      // юзер передумал заходить и закрыл вкладку
      const customData = data.data;
      const store: any = callStore.getState(),
        users = store.meetingUsers.users,
        user = users.find(u => u.waitingId === customData.id);

      if (user) callStore.dispatch(deleteUserAsync(user.id));
    }
    if (data.event === SOCKET_EVENTS.MEETING_SET_TITLE) {
      // СМЕНА НАЗВАНИИ ВСТРЕЧИ
      const customData: {
        link: string,
        title: string
      } = data.data;
      callStore.dispatch(setConference({
        title: customData.title
      }))
    }
    if (data.event === SOCKET_EVENTS.MEETING_DISABLE_SHARE) {
      callStore.dispatch(setConferenceSettings({
        everyoneCanShare: false
      }))
    }
    if (data.event === SOCKET_EVENTS.MEETING_ENABLE_SHARE) {
      callStore.dispatch(setConferenceSettings({
        everyoneCanShare: true
      }))
    }
    if (data.event === SOCKET_EVENTS.MEETING_ROOM_STATUS) {
      callStore.dispatch(setConferenceSettings({everyoneCanShare: data.data.all_share}))
    }
    if (data.event === SOCKET_EVENTS.MEETING_DISABLE_CHAT) {
      callStore.dispatch(setConferenceSettings({
        everyoneCanChat: false
      }))
    }
    if (data.event === SOCKET_EVENTS.MEETING_ENABLE_CHAT) {
      callStore.dispatch(setConferenceSettings({
        everyoneCanChat: true
      }))
    }
    if (data.event === SOCKET_EVENTS.MEETING_SET_CONFIRM_AUTH) {
      const customData: {
        link: string,
        value: boolean
      } = data.data;
      callStore.dispatch(setConferenceSettings({
        waitingRoom: customData.value
      }))
    }
    if (data.event === SOCKET_EVENTS.MEETING_INFO) {

      const customData = data.data;
      const {
        meetingUsers: {profile},
        conference: {conference: {hash, title}}
      } = callStore.getState();

      if (customData.link === hash) {

        if (customData.tmp_key === 'conference') {
          // если я создатель, я могу открыть ее
          if (customData.owner) {
            if (customData.open) {
              // если открыта join
              Emit(SOCKET_ACTIONS.MEETING_JOIN, {link: customData.link})
            } else {
              // если закрыта создадим и откроем
              Emit(SOCKET_ACTIONS.MEETING_CREATE, {link: customData.link, title: formatUserName(profile)})
            }
            callStore.dispatch(setRout(''))
          } else {
            if (customData.open){
              if (!!customData.confirm_auth) {
                callStore.dispatch(setRout(ROUT_TYPES.GUEST))
              } else {
                Emit(SOCKET_ACTIONS.MEETING_JOIN, {link: customData.link})
              }
            } else {
              callStore.dispatch(setRout(ROUT_TYPES.GUEST))
            }
          }
          callStore.dispatch(setIsAwaitReady(false))

        }

        if (customData.tmp_key === 'title') {
          document.dispatchEvent(new CustomEvent('set-title', {detail: customData.title}));
        }

        if (customData.tmp_key === 'checkingStart') {
          if (customData.open) {
            clearTimeout(timeOutConnect);
            Emit(SOCKET_ACTIONS.MEETING_JOIN, {link: customData.link});
          } else checkRecursive(customData.link);
        }

        if (customData.tmp_key === 'start') {
          // смотрим открыта ли конфа
          if (customData.open) {
            // подключаемся
            Emit(SOCKET_ACTIONS.MEETING_JOIN, {link: customData.link});
          } else {
            // если овнер то комната откроется иначе придет ошика 107
            emitEvent({
              'event': SOCKET_ACTIONS.MEETING_CREATE,
              'link': customData.link,
              'title': title,
              'version': SOCKET_VERSION
            })
          }
        }

      }

    }
  });
}

export const Emit = (type: string, data?: any) => {
  if (!data.attributes?.debugUser)
    meetLog('emit', type, data);

  if (type === SOCKET_ACTIONS.MEETING_JOIN) {
    const params: any = {'event': SOCKET_ACTIONS.MEETING_JOIN, 'link': data.link}
    const store: any = callStore.getState(),
      users = store.meetingUsers.users,
      profile = store.meetingUsers.profile,
      iAm = users.find((v: User.IUsersTo) => v.isLocal);

    const arr = iAm.name.split(' '),
      name = arr[0],
      surname = arr.splice(1);

    params.name = name;
    params.surname = surname.length > 0 ? surname.join(' ') : '';
    params.audio = iAm.audio;
    params.video = iAm.video;
    params.share = iAm.isShare;
    socket.emit(SOCKET_CHANNEL, params);
  } else if (type === SOCKET_ACTIONS.MEETING_CREATE) {
    const store: any = callStore.getState(),
      users = store.meetingUsers.users,
      profile = store.meetingUsers.profile,
      iAm = users.find((v: User.IUsersTo) => v.isLocal)

    const params: any = {
      'event': SOCKET_ACTIONS.MEETING_CREATE,
      'link': data.link,
      'version': SOCKET_VERSION
    }

    if (data.title) {
      params['title'] = data.title
    } else if (iAm.name) {
      params['title'] = formatUserName(iAm)
    } else if (profile && profile.name) {
      // TODO сююда не должно попасть, наблюдаем
      params['title'] = formatUserName(profile)
    }

    socket.emit(SOCKET_CHANNEL, params);
  } else if (type === SOCKET_ACTIONS.MEETING_SET_HOST)
    socket.emit(SOCKET_CHANNEL, {'event': SOCKET_ACTIONS.MEETING_SET_HOST, 'id': data.id, 'link': data.link});
  else if (type === SOCKET_ACTIONS.MEETING_CUSTOM) {
    socket.emit(SOCKET_CHANNEL, {
      'event': SOCKET_ACTIONS.MEETING_CUSTOM,
      'link': data.link,
      'other': data.attributes
    });
  } else if (type === SOCKET_ACTIONS.MEETING_KICK) {
    socket.emit(SOCKET_CHANNEL, {'event': SOCKET_ACTIONS.MEETING_KICK, 'link': data.link, 'id': data.id});
  } else if (type === SOCKET_ACTIONS.MEETING_CHANGE_NAME) {
    socket.emit(SOCKET_CHANNEL, {
      'event': SOCKET_ACTIONS.MEETING_CHANGE_NAME,
      'link': data.link,
      'name': data.name,
      'surname': data.surname,
      'id': data.user_id
    });
  } else if (type === SOCKET_ACTIONS.MEETING_ACCEPT_ENTRY) {
    socket.emit(SOCKET_CHANNEL, {
      'event': SOCKET_ACTIONS.MEETING_ACCEPT_ENTRY,
      'link': data.link,
      'id': data.id
    });
  } else if (type === SOCKET_ACTIONS.MEETING_REJECT_ENTRY) {
    socket.emit(SOCKET_CHANNEL, {
      'event': SOCKET_ACTIONS.MEETING_REJECT_ENTRY,
      'link': data.link,
      'id': data.id
    });
  } else if (type === SOCKET_ACTIONS.MEETING_GET_ENTRY) {
    socket.emit(SOCKET_CHANNEL, {
      'event': SOCKET_ACTIONS.MEETING_GET_ENTRY,
      'link': data.link
    });
  } else if (type === SOCKET_ACTIONS.MEETING_START_AUDIO) {
    socket.emit(SOCKET_CHANNEL, {
      'event': SOCKET_ACTIONS.MEETING_START_AUDIO,
      'link': data.link
    });
  } else if (type === SOCKET_ACTIONS.MEETING_END_AUDIO) {
    socket.emit(SOCKET_CHANNEL, {
      'event': SOCKET_ACTIONS.MEETING_END_AUDIO,
      'link': data.link
    });
  } else if (type === SOCKET_ACTIONS.MEETING_START_VIDEO) {
    socket.emit(SOCKET_CHANNEL, {
      'event': SOCKET_ACTIONS.MEETING_START_VIDEO,
      'link': data.link
    });
  } else if (type === SOCKET_ACTIONS.MEETING_HOST_CLOSE) {
    socket.emit(SOCKET_CHANNEL, {
      'event': SOCKET_ACTIONS.MEETING_HOST_CLOSE,
      'link': data.link
    });
  } else if (type === SOCKET_ACTIONS.MEETING_END_VIDEO) {
    socket.emit(SOCKET_CHANNEL, {
      'event': SOCKET_ACTIONS.MEETING_END_VIDEO,
      'link': data.link
    });
  } else if (type === SOCKET_ACTIONS.MEETING_JOIN_CANCEL) {
    socket.emit(SOCKET_CHANNEL, {
      'event': SOCKET_ACTIONS.MEETING_JOIN_CANCEL,
      'link': data.link
    });
  } else if (type === SOCKET_ACTIONS.MEETING_SET_TITLE) {
    socket.emit(SOCKET_CHANNEL, {
      'event': SOCKET_ACTIONS.MEETING_SET_TITLE,
      'link': data.link,
      'title': data.title
    })
  } else if (type === SOCKET_ACTIONS.MEETING_DISABLE_SHARE) {
    // ОТКЛЮЧИТЬ ШЕЙРИНГ ДЛЯ ВСЕХ

    socket.emit(SOCKET_CHANNEL,
      {
        'event': SOCKET_ACTIONS.MEETING_DISABLE_SHARE,
        'link': data.link
      })

  } else if (type === SOCKET_ACTIONS.MEETING_USERS) {
    socket.emit(SOCKET_CHANNEL,
      {
        'event': SOCKET_ACTIONS.MEETING_USERS,
        'link': data.link
      })
  }
  if (type === SOCKET_ACTIONS.MEETING_ENABLE_SHARE) {
    // ВКЛЮЧИТЬ ШЕЙРИНГ ДЛЯ ВСЕХ

    socket.emit(SOCKET_CHANNEL,
      {
        'event': SOCKET_ACTIONS.MEETING_ENABLE_SHARE,
        'link': data.link
      })

  }
  if (type === SOCKET_ACTIONS.MEETING_ENABLE_CHAT) {
    // ВКЛЮЧИТЬ ЧАТ ДЛЯ ВСЕХ

    socket.emit(SOCKET_CHANNEL,
      {
        'event': SOCKET_ACTIONS.MEETING_ENABLE_CHAT,
        'link': data.link
      })

  }
  if (type === SOCKET_ACTIONS.MEETING_DISABLE_CHAT) {
    // ОТКЛЮЧИТЬ ЧАТ ДЛЯ ВСЕХ
    socket.emit(SOCKET_CHANNEL,
      {
        'event': SOCKET_ACTIONS.MEETING_DISABLE_CHAT,
        'link': data.link
      })
  }

  if (type === SOCKET_ACTIONS.MEETING_ENABLE_CONFIRM_AUTH) {
    // ОТКЛЮЧИТЬ ЧАТ ДЛЯ ВСЕХ
    socket.emit(SOCKET_CHANNEL,
      {
        'event': SOCKET_ACTIONS.MEETING_ENABLE_CONFIRM_AUTH,
        'link': data.link
      })
  }
  if (type === SOCKET_ACTIONS.MEETING_DISABLE_CONFIRM_AUTH) {
    // ОТКЛЮЧИТЬ ЧАТ ДЛЯ ВСЕХ
    socket.emit(SOCKET_CHANNEL,
      {
        'event': SOCKET_ACTIONS.MEETING_DISABLE_CONFIRM_AUTH,
        'link': data.link
      })
  }
}
