import React, {useCallback, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiSelectorConfirmType } from "../../redux/selectors/ui";
import { DIALOG_CONFIRM } from "../../constants/contants";
import { setConfirmType } from "../../redux/slices/ui";
import DialogRemoveFromMeeting from "./variants/DialogRemoveFromMeeting";
import DialogPermissions from "./variants/DialogPermissions";
import DialogMuteUser from "./variants/DialogMuteUser";

import { ChatInfo, Provider, ThemePicker } from "@clout-team/web-components";
import DialogRenameMeeting from "./variants/DialogRenameMeeting";
import {contextRoot} from "../../App";

const DialogsConfirm = () => {
  const dialogType = useSelector(uiSelectorConfirmType),
    lang = useSelector((state: any) => state.localization),
    L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]),
    dispatch = useDispatch();

  useEffect(() => {
    if (dialogType !== '') {
      contextRoot?.classList?.remove('active');
    }
  }, [dialogType])

  switch (dialogType.type) {
    case DIALOG_CONFIRM.MUTE_USER:
      return (
        <DialogMuteUser
          L={L}
          user={dialogType?.data}
          onClose={() => dispatch(setConfirmType({ type: "" }))}
        />
      );
    case DIALOG_CONFIRM.TURN_OFF_VIDEO:
      return (
        <DialogMuteUser
          turnType={"video"}
          L={L}
          user={dialogType?.data}
          onClose={() => dispatch(setConfirmType({ type: "" }))}
        />
      );
    case DIALOG_CONFIRM.REMOVE_FROM_MEETING:
      return (
        <DialogRemoveFromMeeting
          L={L}
          user={dialogType?.data}
          onClose={() => dispatch(setConfirmType({ type: "" }))}
        />
      );
    case DIALOG_CONFIRM.SET_PERMISSIONS:
      return <DialogPermissions />;

    case DIALOG_CONFIRM.PROFILE_INFO:
      return (
        <ChatInfo
          chatInfoActions={{}}
          myProfile={true}
          onClose={() => {
            dispatch(setConfirmType({ type: "" }));
          }}
          user={dialogType?.data}
          contact={dialogType?.data}
          contactViewMode={true}
          useContactsControllers={false}
        />
      );

    case DIALOG_CONFIRM.CHOOSE_THEME:
      return (
        <Provider>
          <ThemePicker
            isModal
            roundedCorners
            // onSetTheme={({ name, highContrast }) => {
            //   changeTheme(name);
            //   dispatch(
            //     setChatSettings({
            //       isInvertRightBar: highContrast,
            //     })
            //   );
            // }}
            highContrast={'on'}
            onClose={() => dispatch(setConfirmType({ type: "" }))}
          />
        </Provider>
      );
    case DIALOG_CONFIRM.RENAME_MEETING:
      return (
        <DialogRenameMeeting
          onClose={() => dispatch(setConfirmType({ type: "" }))}
        />
      );
    default:
      return null;
  }
};

export default DialogsConfirm;
