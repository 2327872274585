import { useSelector } from "react-redux";
import React, {useCallback, useEffect} from "react";
import './error.scss';
import { Button, Layout, Text, ProviderFunctions } from "@clout-team/web-components";
import { ERROR_LINK, LAND_PAGE } from "../../constants/contants";
import {isShowLoading} from "../../helpers/functions";
import {isIphone} from "@clout-team/helpers";
const {sendToIos} = ProviderFunctions;

const splitted = ERROR_LINK.split("/")
const PageError = () => {
  const lang = useSelector((state: any) => state.localization);
  const L = useCallback((key: string) => lang.translations[lang.locale][key], [lang]);

  useEffect(() => {
    setTimeout(() => isShowLoading(false), 300);
  }, [])

  return (
    <>
      <div className={'page-error'}>
        <div className="floating-image" style={{
          backgroundImage: "url(./error-link-bg.png)"
        }} />
        <Layout gap="xlg" className="page-error-content" isAlignCenter vertical>
          <Text className="title-text">
            {L("calls.header.check_meeting_link")}
          </Text>
          <Layout gap="none" className="page-error--header" vertical>
            <Text type='secodary' size="header" align='center' className="link-example">
              {L("calls.link.the_link_is_incorrect")}
            </Text>
            <Text type="secodary" size="header" align='center'>
              {L("calls.label.example")?.replace('{_example_}.', "")}
              {window.location.hostname}/<b>{splitted[splitted.length - 1]}</b>
            </Text>
          </Layout>
          <Button size="lg" className="rounded-corners-8" onClick={() =>  {
            if (isIphone()) {
              sendToIos('meet_close', '')
            } else {
              document.location.href = LAND_PAGE
            }
          }}>
            <Text type="" size="body-bold">
              {L("calls.button.return_to_the_home_page")}
            </Text>
          </Button>
        </Layout>
      </div>
    </>
  )
}

export default PageError
