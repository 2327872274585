import {createAsyncThunk, createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";
import {RootState} from "../stores/callStore"
import {ROUT_TYPES} from "../../constants/contants";
import {setRout} from "./ui";
import {emitEvent} from "../../helpers/socketHelper";
import {getToken} from "../../helpers/functions";

export interface IPermissions {
  camera?: boolean
  microphone?: boolean
}

export interface IVideoSize {
  width: number
  height: number
}

export const setConferenceInfo = createAsyncThunk<any,
  string,
  { state: RootState }>(
  'conference/setInfo',
  async (hash, thunkAPI) => {

    const token = getToken();

    if (token) {
      let socketTimeout: ReturnType<typeof setTimeout>;
      const awaitSocket = () => {
        socketTimeout = setTimeout(() => {
          // @ts-expect-error
          if (window.soketIsReady) {
            clearTimeout(socketTimeout);
            emitEvent({
              'event': 'info',
              'tmp_key': 'conference',
              'link': hash
            })
          } else awaitSocket();
        }, 1000)
      }
      awaitSocket()
    } else {
      thunkAPI.dispatch(setRout(ROUT_TYPES.GUEST))
    }

  }
)

const conferenceSlice: Slice = createSlice({
  name: 'conference',
  initialState: {
    subject_hash: '',
    conference: {
      isConference: true,
      roomId: 0,
      hash: '',
      link: '',
      token: '',
      title: '',
      permissions: {
        camera: true,
        microphone: true
      }
    }
  },
  reducers: {
    setMeetingLink: (state, {payload}: PayloadAction<any>) => {
      state.conference.link = payload;
    },
    setToken: (state, {payload}: PayloadAction<any>) => {
      state.conference.token = payload;
    },
    setSubject: (state, {payload}: PayloadAction<any>) => {
      state.subject_hash = payload;
    },
    setPermissions: (state, {payload}: PayloadAction<any>) => {
      state.conference.permissions = {...state.conference.permissions, ...payload};
    },
    setConference: (state, {payload}: PayloadAction<any>) => {
      state.conference = {...state.conference, ...payload};
    },
    setRoomId: (state, {payload}: PayloadAction<any>) => {
      state.conference.roomId = payload
    },
    setConferenceHash: (state, {payload}: PayloadAction<any>) => {
      state.conference.hash = payload
    },
    setIsConference: (state, {payload}: PayloadAction<any>) => {
      state.conference.isConference = payload
    }
  },
  extraReducers: {}
})

export const {
  setToken,
  setSubject,
  setConference,
  setConferenceHash,
  setIsConference,
  setRoomId,
  setPermissions,
  setMeetingLink
} = conferenceSlice.actions;
export default conferenceSlice.reducer;
