import {createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";
import {getReferrer} from "../../helpers/functions";

const initialState = {
  isReversLocalVideo: true,
  isRememberUserName: true,
  referrer: getReferrer(),
  chatSettings: {
    isInvertRightBar: false,
    isShowNotification: true,
    isShowSystemMessages: false,
    isShowCounter: true,
  },

  /** @description Список активных устройств */
  activeDevices: {
    audio: '',
    video: '',
    speaker: ''
  },

  /** @description Настройки конференции */
  conferenceSettings: {
    everyoneCanShare: false,
    everyoneCanChat: true,
    waitingRoom: true,
    showChatPreviews: false
  },

  /** @description blur my background */
  blurMyBackground: false,
}

const settingsSlice: Slice<typeof initialState> = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setReferrer: (state, {payload}: PayloadAction<any>) => {
      state.referrer = payload;
    },
    setIsReverseLocalVideo: (state, {payload}: PayloadAction<any>) => {
      state.isReversLocalVideo = payload;
    },
    setIsRememberUserName: (state, {payload}: PayloadAction<any>) => {
      state.isRememberUserName = payload;
    },
    setChatSettings: (state, {payload}: PayloadAction<any>) => {
      state.chatSettings = {...state.chatSettings, ...payload};
    },
    setActiveDevices: (state, {payload}: PayloadAction<any>) => {
      state.activeDevices = {...state.activeDevices, ...payload};
    },
    setConferenceSettings: (state, {payload}: PayloadAction<Record<string, boolean>>) => {
      state.conferenceSettings = {...state.conferenceSettings, ...payload}
    },
  },
  extraReducers: {}
})

export const {
  setIsRememberUserName,
  setChatSettings,
  setIsReverseLocalVideo,
  setIsInvertRightBar,
  setActiveDevices,
  setConferenceSettings,
  setReferrer
} = settingsSlice.actions;

export default settingsSlice.reducer;
